import {Component, OnInit} from '@angular/core';

import {faIcon} from 'src/app/core/icon/fa.icon';
import {ActivatedRoute} from '@angular/router';
import {ManPowerState} from 'src/app/core/enums/man-power.enum';
import {ManpowerService, MT} from '../../core/services/manpower.service';
import {EmployeeService} from '../../core/services/employee.service';
import {UserManagementService} from '../../core/services/user-management.service';
import {RequestTrainingService} from '../../core/services/request-training.service';

@Component({
  selector: 'app-man-power',
  templateUrl: './man-power.component.html',
  styleUrls: ['./man-power.component.scss'],
})
export class ManPowerComponent implements OnInit {
  displayItem = ManPowerState.LIST;
  faIcon = faIcon;
  id
  forAction
  isAdmin = false
  isHrAdmin = false;
  readonly allowList = ['00155', '00511', '00202', '00370', '00409']

  constructor(
    private manpowerService: ManpowerService,
    private activatedRoute: ActivatedRoute,
    private employeeService: EmployeeService,
    private userRoleService: UserManagementService,
    private requestTrainingService: RequestTrainingService
  ) {
  }

  async ngOnInit() {
    const {id} = this.activatedRoute.snapshot.params
    const {action} = this.activatedRoute.snapshot.data
    if (id) {
      this.id = id
      this.forAction = action
      this.displayItem = ManPowerState.EDIT
    } else {
      const currentProfile = await this.employeeService.me().toPromise()
      this.isAdmin = this.allowList.includes(currentProfile.empID)
      const roles = await this.requestTrainingService.getUserRole().toPromise()
      this.isHrAdmin  = roles.some(role => role.role === 'HRAdmin')
    }
    // this.manpowerService.deptRelation = await this.manpowerService.getAllDepartment().toPromise()
  }

  routeToManPowerView(data) {
    if (!data) {
      this.displayItem = ManPowerState.NEW;
    }
  }

  onBack(data) {
    this.displayItem = ManPowerState.LIST;
  }

   async sendMailTo(mt: MT) {
    console.log('Call send mail')
    this.manpowerService.sendMail(mt).toPromise()
      .then(() => console.log('Success'))
      .catch(() => console.error('Fail'))
  }
}
